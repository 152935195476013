import getApiData from "../shared/helpers/apiHelper";
import { toast } from "react-toastify";
import { isArray } from "lodash";
import { countryData } from "components/DefaultHealth";
import siteConfig from "config/site.config";

//API for receipt download
export const downloadReceipt = async function (
  transactionId: any,
  setDownloadLoad?: any,
  loadID?: any
) {
  if (loadID) {
    setDownloadLoad(transactionId);
  } else {
    setDownloadLoad(true);
  }

  try {
    const res = await getApiData(
      `donation/admin-receipt/${transactionId}`,
      {},
      "POST"
    );
    if (res.success) {
      setDownloadLoad(false);
      window.open(res.url, "_blank");
    } else {
      setDownloadLoad(false);
      toast.error(res.message);
    }
  } catch (error) {
    setDownloadLoad(false);
    toast.error("Something went wrong");
  }
};

//API For get country list
export const getCountryLists = async function (setCountryBank?: any) {
  try {
    const res = await getApiData("currency/list?all=1", {}, "GET");
    if (res.success) {
      setCountryBank(isArray(res.data) ? res.data : []);
      return res.data;
    } else {
      setCountryBank([]);
      toast.error(res.message);
    }
  } catch (error) {
    setCountryBank([]);
    toast.error("Something went wrong");
  }
};

//API For get coomo settigs data list
export const getCommonSettings = async function (slug?: any) {
  try {
    const res = await getApiData(`setting/find-setting/${slug}`, {}, "GET");
    if (res.success) {
      return res.data;
    } else {
      // toast.error(res.message);
    }
  } catch (error) {
    toast.error("Something went wrong");
  }
};

//API For get notification badge counts
export const getBadgeCount = async function (
  dispatch,
  setBadgeCount,
  badgeCount
) {
  try {
    const res = await getApiData("notification/admin/badge-count", {}, "GET");
    if (res.success) {
      // let tempVal = flattenDeep([badgeCount, res?.data]);
      dispatch(setBadgeCount(res.data));
    } else {
      dispatch(setBadgeCount(badgeCount));
    }
  } catch (error) {
    console.log("🚀 ~ notification badge counts ~ err", error);
  }
};

//API For get help notification badge counts
export const getHelpBadgeCount = async function (
  dispatch,
  setHelpBadgeCount,
  helpBadgeCount
) {
  try {
    const res = await getApiData(
      "notification/admin/badge-count?help_request=1",
      {},
      "GET"
    );
    if (res.success) {
      // let tempVal = flattenDeep([helpBadgeCount, res?.data]);
      dispatch(setHelpBadgeCount(res.data));
    } else {
      dispatch(setHelpBadgeCount(helpBadgeCount));
    }
  } catch (error) {
    console.log("🚀 ~ notification badge counts ~ err", error);
  }
};

export const getCountryCode = (country: any, fName: any) => {
  return countryData
    .filter((item: any) => item.isoCode === country && item.name === fName)
    .map((item: any) => item.dialCode)
    .toString();
};

export const getCountryFullName = (country: any, fName: any) => {
  return countryData
    .filter(
      (item: any) => item.dialCode === `+${country}` && item.name === fName
    )
    .map((item: any) => item.name)
    .toString();
};

export const getLocation = async (checkOnly = false) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (data) => {
        resolve(data.coords);
      },
      (err) => {
        resolve(err);
      },
      { enableHighAccuracy: true, timeout: 20000 }
    );
  });
  return null;
};

export const getCountryName = async (type: any = "") => {
  return new Promise(async (resolve) => {
    const gLocation: any = await getLocation();
    // let countryFullNameNew = '';
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${gLocation.latitude},${gLocation.longitude}&key=${siteConfig?.mapAPI}`
    )
      .then((response) => response.json())
      .then(async (result) => {
        if (result.status === "OK") {
          const { results } = result;
          const address_components = results[0].address_components;
          const formatted_address = results[0].formatted_address;
          const locations = address_components.filter(
            (loc: any) => loc.types[0] === "country"
          );
          const country = locations[0].short_name || "IN";
          const countryFName = locations[0].long_name || "";
          const countryCodeD = getCountryCode(country, countryFName) || "+91";
          const countryFullNameNew = getCountryFullName(
            countryCodeD.substring(1) || "91",
            countryFName
          );
          let obj = {
            countryName: countryFullNameNew,
            countryCode: countryCodeD,
            countryShortName: country,
            address: formatted_address,
            gLocation: gLocation,
            onlyShortName: locations[0].short_name.toLowerCase(),
          };
          resolve(obj);
        }
      });
  });
};

export const displayAmount = (
  amount: any,
  currency?: any,
  currency_code?: any
) => {
  let format = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currency_code || "INR",
    minimumFractionDigits: 2,
  });
  if (currency === "$") {
    format = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency_code || "USD",
      minimumFractionDigits: 2,
    });
  }
  return format.format(amount);
  // return Number(amount)
  //   ?.toFixed(2)
  //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const sendOTPSMSAPI = async (
  phone: any,
  phoneCountry: any
  // uuID: any,
) => {
  try {
    let APIData = {
      phone: phone,
      phone_code: phoneCountry,
      // platform: IOS ? 'Ios' : 'Android',
      // uuid: uuID || fcmToken,
      // otp_platform: 'app',
      platform: "web",
    };
    console.log("sendOTPSMSAPI APIData", APIData);
    const backResp: any = await getApiData(`user/send-otp`, APIData, "POST");
    console.log("sendOTPSMSAPI ~ backendResp", backResp);
    if (backResp && backResp?.success) {
      return {
        sent: true,
        msg: backResp?.message ? backResp?.message : "OTP Sent on your phone",
      };
    } else {
      return {
        sent: false,
        msg: backResp?.message
          ? backResp?.message
          : "Something went wrong while sending OTP",
      };
    }
  } catch (error) {
    console.log("OPSS Something went wrong ", error);
    toast.error("Oops! Something went wrong");
    return {
      sent: false,
      msg: "Something went wrong while sending OTP",
    };
  }
};

export const getAvatarText = (item) => {
  // For objects with first_name and last_name
  if (item?.first_name && item?.last_name) {
    return `${item?.first_name[0]}${item?.last_name[0]}`.toUpperCase();
  }
  // For objects with name
  if (item?.name) {
    const nameParts = item?.name?.split(" ");

    // If it's a single word (e.g., "Bhargav"), take the first two characters BH
    if (nameParts?.length === 1) {
      return nameParts[0].substring(0, 2).toUpperCase();
    }
    // If it has multiple words, take the first character of each
    return (nameParts[0][0] + nameParts[1][0]).toUpperCase();
  }
  return "NA"; // Fallback text
};

export const capitalizeFirstLetter = (str) => {
  return str
    ?.split(" ")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(" ");
};

export const slugToString = (slug) => {
  return slug
    .split("-") // Split by dashes
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join with spaces
};
